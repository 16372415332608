.dropdown {
  position: relative;
}

.dropdown-icon {
  display: flex;
  margin-left: 1rem;
  padding-left: 0;
  padding-right: 1rem;
  align-items: center;
}

.dropdown-content {
  display: none;
  position: absolute;
  margin-left: 1rem;
  margin-top: 0.2rem;
}
