body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

div#root,
div#root .App {
  height: 100%;
}

.App {
  text-align: center;
  font-size: 16px;
}

.App-header {
  background-color: #2c3e50;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.header-text {
  font-size: 1.5rem;
  margin: 0;
}

@media only screen and (max-width: 680px) {
  .header-text {
    display: none;
  }
}

.image-uploader {
  margin-left: 1rem;
  margin-right: 1rem;
}

.toggle-button {
  font-size: 3rem;
  height: 80%;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media only screen and (max-width: 680px) {
  .toggle-button {
    font-size: 2rem;
  }
}

main {
  flex: 1;
  background-color: #ecf0f1;
}

/* mobile scrolling fix from https://stackoverflow.com/a/33953987/11520964 */
body {
  /* prevent overscroll bounce*/
  background-color: #ecf0f1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
